@import "../../variables";

button {
  border-radius: 5px;
  border: none;

  a {
    text-decoration: none;
  }

  &:hover:after {
    transition: all 3s;
    content: '>';
    padding-left: 4px;
  }

  &:hover {
    cursor: pointer;
  }
}