@import '../../variables';


.services-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 23vh;

  .massages-title {
    margin-top: 7rem;
    font-family: $font-accent;
    font-size: 2.5rem;
    color: $teal;
    @include devices(mobile and tablet) {
      margin-top: 4.5rem;
    }
    @include keyframes(slide-in) {
      from {
        transform: translateX(-300px);
      }
      to {
        transform: translateX(18px);
      }
    }
    @include animate(slide-in, 1500ms, ease-in-out)
  }

  .massages-grid {
    padding: 5rem 0 7rem 0;
    display: grid;
    max-width: 80%;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
    @include devices(mobile and tablet) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2rem 0 4rem 0;
    }
    @include keyframes(slide-in) {
      from {
        transform: translateX(-300px);
      }
      to {
        transform: translateX(18px);
      }
    }
    @include animate(slide-in, 1500ms, ease-in-out)
  }

  .tarifs-text-container {
    max-width: 80%;
    margin: 0 auto;
  }

  .alternative-payment-text-container {
    max-width: 80%;
    margin: 0 auto;
    @include devices(mobile) {
      max-width: 70%;
      text-align: justify;
    }
  }

  .tarifs {
    width: 100vw;
    padding: 4rem 0;
    background-color: $pink;
    color: white;
    @include devices(tablet) {
      font-size: 1.1rem;
    }

    h2 {
      font-family: $font-accent;
      font-size: 2.2rem;
    }
  }

  .alternative-payment {
    padding: 7rem 0;
    text-align: left;
    background-image: url('../../assets/fond-2.png');
    background-position: center;
    background-size: cover;
    @include devices(tablet) {
      line-height: 1.2rem;
    }
    @include devices(mobile) {
      padding: 5rem 0;
    }

    h3 {
      font-family: $font-accent;
      font-size: 1.5rem;
      margin-bottom: 3rem;
      color: $burgundy;
    }

    p {
      font-size: 0.9rem;
    }

    strong {
      color: $teal;
    }
  }
}



