@import '../../variables';


.home-page {

  .landing-section {
    .landing-section-title {
      font-family: $font-accent;
      font-size: 1.5rem;
      color: $burgundy;
      height: 22vh;
      background-image: url('../../assets/fond-2.png');
      background-position: center;
      background-size: cover;
      text-align: center;
      padding: 25vh 0 10vh 0;
      @include devices(tablet) {
        height: 20vh;
      }
      @include devices(mobile) {
        height: 15vh;
      }


      h1 {
        text-transform: uppercase;
        margin-top: 6rem;
        margin-bottom: 0;
        @include devices(tablet) {
          margin-top: 4rem;
          font-size: 1.6rem;
        }
        @include devices(mobile) {
          margin-top: 2.8rem;
          font-size: 1.7rem;
        }

        @include keyframes(slide-in) {
          from {
            transform: translateX(-300px);
          }
          to {
            transform: translateX(18px);
          }
        }
        @include animate(slide-in, 1500ms, ease-in-out)
      }

      h2 {
        margin-top: 0;
        @include keyframes(slide-in) {
          from {
            transform: translateX(-300px);
          }
          to {
            transform: translateX(18px);
          }
        }
        @include animate(slide-in, 1500ms, ease-in-out);
        @include devices(mobile) {
          font-size: 1.8rem;
        }
      }
    }

    .landing-section-text-and-image {
      display: flex;
      flex-direction: row;
      min-height: 65vh;
      @include devices(mobile and tablet)  {
        flex-direction: column;
      }

      .landing-section-text {
        background-color: $light-grey;
        width: 50.5%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        @include devices(mobile and tablet) {
          width: 100%;
          justify-content: center;
          padding-top: 30px;
        }


        .landing-p {
          text-align: left;
          @include devices(mobile) {
            text-align: center;
            padding: 0 20px;
          }
          @include keyframes(slide-in) {
            from {
              transform: translateX(-300px);
            }
            to {
              transform: translateX(18px);
            }
          }
          @include animate(slide-in, 2000ms, ease-in-out);

          p {
            padding-top: 40px;
            color: $burgundy;
            font-size: 1.2rem;
            font-weight: bold;
            margin-left: 12%;
            font-family: $font-landing;

            @include devices(mobile) {
              margin-left: 0;
              padding-top: 20px;
              font-size: 1.1rem;
            }

            span {
              display: block;
              padding: 5px;
            }
          }
        }

        .landing-ul {
          text-align: left;
          @include devices(tablet) {
            padding-top: 0;
            padding-right: 40px;
          }
          @include devices(mobile) {
            padding-top: 12px;
          }

          @include keyframes(slide-in) {
            from {
              transform: translateX(-300px);
            }
            to {
              transform: translateX(18px);
            }
          }
          @include animate(slide-in, 2500ms, ease-in-out);


          ul {
            margin-left: 10%;
            color: $burgundy;
            @include devices(tablet) {
              margin-left: 8%;
            }

            @include devices(mobile) {
              margin-left: 10%;
            }

            li {
              padding: 7px;
              @include devices(tablet) {
                padding: 8px;
              }
            }
          }
        }

        .landing-button {
          Button {
            font-size: 1.2rem;
            margin: 10px 0 50px 48%;
            padding: 12px 14px;
            border: 2px solid $teal;
            @include keyframes(slide-in) {
              from {
                transform: translateX(-300px);
              }
              to {
                transform: translateX(18px);
              }
            }
            @include animate(slide-in, 1500ms, ease-in-out);
            @include devices(tablet) {
              margin: 3rem 0 3rem 50%;
            }
            @include devices(mobile) {
              margin: 30px 0 60px 0;
            }


            &:hover {
              background-color: rgba(0, 0, 0, 0.2);
              border: 2px solid transparent;

              &::after {
                color: $teal;
              }
            }

            a {
              color: $teal;
            }
          }
        }
      }

      .landing-section-image {
        width: 49.5%;
        @include devices(tablet) {
          height: 620px;
          width: 100%;
        }
        @include devices(mobile) {
          width: 100%;
          height: 370px;
          // display: none;
        }

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }


  .explanation-and-experience {
    .grid-container {
      width: 100vw;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      @include devices(mobile and tablet) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .grid-child {
        height: 100%;
        width: 100%;

        .experience-text-container {
          @include devices(mobile and tablet) {
            width: 80%;
            z-index: 2;
            margin-bottom: -800px;
            background-color: white;
          }
        }

        img {
          height: 100%;
          width: 100%;
          @include devices(tablet) {
            height: 920px;
            margin-bottom: -4px;
          }
          @include devices(mobile) {
            height: 910px;
            margin-bottom: -4px;
            object-fit: cover;
          }
        }
      }

      .explanation-text-container,
      .experience-text-container {
        max-width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin: 40px 0;
        @include devices(mobile and tablet) {
          max-width: 80%;
        }
      }

      .grid-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .subheading {
          font-family: $font-accent;
          font-weight: bold;
          font-size: 2rem;
        }
        @include devices(tablet) {
          padding-top: 0;
        }
      }

      .explanation-text-container {
        color: white;
        @include devices(tablet) {
          text-align: justify;
          padding-bottom: 2rem;
        }
        @include devices(mobile) {
          font-size: 0.9rem;
          text-align: justify;
        }

      }

      .burgundy-bg {
        background-color: $burgundy;
        @include devices(mobile and tablet) {
          width: 80%;
          z-index: 2;
          margin-top: -850px;
          margin-bottom: -50px;
        }
        @include devices(tablet) {
          margin-top: -780px;
        }
      }

      .experience-text-container {
        color: $teal;
        @include devices(tablet) {
          margin-top: 7rem;
          padding: 3rem 0;
        }
        @include devices(mobile) {
          padding: 30px 0;
          font-size: 0.9rem;
        }


        .subheading {
          color: $burgundy;
        }

        ul {
          list-style: none;
          text-align: left;
          @include devices(mobile and tablet) {
            width: 65%;
            padding-left: 0;
          }

          li {
            padding: 5px 0;
          }
        }
      }
    }
  }
}
