@import "../../variables";

.footer {
  width: 100%;
  height: 70px;
  background-color: rgb(98,22,62);
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  @include devices(mobile) {
    padding-bottom: 25px;
  }

  span {
    margin-left: 15%;
  }
}