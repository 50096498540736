@import '../../variables';


.contact-page {
  margin-top: 23vh;
  @include devices (tablet) {
    margin-top: 20vh;
  }
  @include devices(mobile) {
    margin-top: 21vh;
  }


  .contact-section {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    @include devices(mobile and tablet) {
      flex-direction: column;
    }


    .contact-info {
      min-height: 100%;
      width: 50%;
      background-color: $blue;
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include devices(mobile and tablet) {
        width: 100%;
      }

      .contact-info-text-container {
        width: 70%;
        text-align: left;
        padding: 5rem 0;
        @include devices(mobile) {
          padding: 3rem 0;
        }

        h1 {
          font-family: $font-accent;
          font-size: 2.2rem;
          animation: slide-in;
          animation-delay: 3s;
          @include keyframes(slide-in) {
            from {
              transform: translateX(-300px);
            }
            to {
              transform: translateX(18px);
            }
          }
          @include animate(slide-in, 1500ms, ease-in-out);
        }

        Button {
          font-size: 1.2rem;
          padding: 10px;
          margin: 2rem 0;
          background-color: transparent;
          border: 1px solid white;
          @include keyframes(slide-in) {
            from {
              transform: translateX(-300px);
            }
            to {
              transform: translateX(18px);
            }
          }
          @include animate(slide-in, 1500ms, ease-in-out);
          &:hover {
            background-color: rgba(255,255,255, 0.2);
            border: 2px solid transparent;
            &::after {
              color: white
            }
          }
          a {
            color: white;
          }
        }
      }
    }

    .contact-image {
      width: 50%;
      @include devices(mobile and tablet) {
        width: 100%;
        margin-bottom: -4px;
      }
      img {
        width:100%;
        height: 100%;
      }
    }
  }
}
