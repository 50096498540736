/* Colors */
$burgundy: rgb(145,32,92);
$teal: rgb(5,122,153);
$light-grey: rgb(235,235,235);
$pink: rgb(184,117,150);
$blue: rgb(27,117,148);

/* Type faces */
$font-plain: 'Lato', sans-serif;
$font-accent: 'Dancing Script', cursive;
$font-landing: 'Amita', cursive;

/* Media Query Mixin */
@mixin devices ($breakpoint) {
  @if $breakpoint == tablet {
    @media only screen and (max-width: 768px) {
      @content
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (max-width: 481px) {
      @content
    }
  }
}

/* Animation */
@mixin animate($animation, $duration, $method) {
  animation: $animation $duration $method;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content
  }
}
