@import "../../variables";

.nav-container {
  height: 23vh;
  width: 100vw;
  color: $burgundy;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: $light-grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: .9rem;
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 3;
  @include devices(tablet) {
    height: 27vh;
  }
  @include devices(mobile) {
    height: 21vh;
  }

  .nav-logo img {
    height: 120px;
    width: 260px;
    @include devices(tablet) {
      height: 140px;
      width: 260px;
    }
    @include devices(mobile) {
      height: 100px;
      width: 240px;
    }

  }

  .nav-links {
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 3rem;
    @include devices(tablet) {
      padding-bottom: 2rem;
    }
    @include devices(mobile) {
      padding-bottom: 1rem;
    }



    .link {
      text-decoration: none;
      font-weight: bold;
      padding: 0 20px;
      @include devices(tablet) {
        margin: 0 1rem;
      }
      @include devices(mobile) {
        font-size: 0.8rem;
        padding: 0 30px;
      }


      &:hover {
        opacity: 0.8;
      }
    }
  }
}

